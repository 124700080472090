// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 获取省份数据
export const getProvince = (params) => {
    return postRequest('/busi/city/getProvince', params)
}

// 获取城市数据
export const getCity = (params) => {
    return postRequest('/busi/city/getCity', params)
}

// 获取地区统计数据
export const getTjsjArea = (params) => {
    return postRequest('/app/tjsjArea/getTjsjArea', params)
}

// 获取国家统计数据
export const getTjsjCountry = (params) => {
    return postRequest('/app/tjsjCountry/getTjsjCountry', params)
}