// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getGspcList = (params) => {
    return postRequest('/busi/gspc/getByCondition', params)
}
// 添加
export const addGspc = (params) => {
    return postRequest('/busi/gspc/insert', params)
}
// 编辑
export const editGspc = (params) => {
    return postRequest('/busi/gspc/update', params)
}
// 删除
export const deleteGspc = (params) => {
    return postRequest('/busi/gspc/delByIds', params)
}
// 计算
export const calcGspc = (params) => {
    return postRequest('/app/gspc/calc', params)
}
