<template>
  <div class="wake-add-edit">
    <!-- Drawer抽屉 -->
    <Drawer :title="title" v-model="visible" width="720" on-close="handleClose()" :mask-closable="false">
      <Form ref="form" :model="form" :rules="formValidate" label-position="top">
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="受害人姓名" prop="shr">
            <Input v-model="form.shr" :maxlength="15" prefix="ios-contact" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="性别" prop="xb">
            <Select prefix="ios-cube-outline" v-model="form.xb">
              <Option v-for="(item, i) in this.$store.state.dict.xb" :key="i" :value="item.value">{{ item.title }}
              </Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="出生年月日" prop="csrq">
            <Date-picker type="date" :editable="false" :value="form.csrq" @on-change="form.csrq = $event"
              format="yyyy-MM-dd" clearable></Date-picker>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="受害日期" prop="shrq">
            <Date-picker type="date" :editable="false" :value="form.shrq" @on-change="form.shrq = $event"
              format="yyyy-MM-dd" clearable></Date-picker>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="年工资标志" prop="ngzbz">
            <i-switch v-model="form.ngzbz" :true-value="1" :false-value="0"></i-switch>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="受害人工资" prop="shrgz">
            <Input v-model="form.shrgz" :maxlength="10" prefix="logo-bitcoin" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="停工留薪天数" prop="tglxts">
            <Input v-model="form.tglxts" :maxlength="3" prefix="md-time" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="护理等级" prop="hldj">
            <Select prefix="ios-cube-outline" v-model="form.hldj">
              <Option v-for="(item, i) in this.$store.state.dict.hldj" :key="i" :value="item.value">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="职业病" prop="zyb">
            <Select prefix="ios-cube-outline" v-model="form.zyb">
              <Option v-for="(item, i) in this.$store.state.dict.zyb" :key="i" :value="item.value">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="伤残等级" prop="scdj">
            <Select prefix="ios-cube-outline" v-model="form.scdj">
              <Option v-for="(item, i) in this.$store.state.dict.scdj" :key="i" :value="item.value">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="医疗费（总）" prop="ylf">
            <Input v-model="form.ylf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="伙食补助费（总）" prop="hsbzf">
            <Input v-model="form.hsbzf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="交通费（总）" prop="jtf">
            <Input v-model="form.jtf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="食宿费（总）" prop="ssf">
            <Input v-model="form.ssf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="康复治疗费（总）" prop="kfzlf">
            <Input v-model="form.kfzlf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="住院期间护理费（总）" prop="hlf">
            <Input v-model="form.hlf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="辅助器具费（总）" prop="fzqjf">
            <Input v-model="form.fzqjf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="劳动能力鉴定费（总）" prop="jdf">
            <Input v-model="form.jdf" prefix="logo-usd" :maxlength="10" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="省份" prop="province">
            <Select prefix="ios-cube-outline" v-model="form.tmpProvince" @on-change="changeProvince">
              <Option v-for="(item, i) in formExt.listProvince" :key="i" :value="item.id + '|' + item.rownum">
                {{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="城市" prop="city">
            <Select prefix="ios-cube-outline" v-model="form.tmpCity" clearable @on-change="changeCity"
              :disabled="formExt.cityDisabled">
              <Option v-for="(item, i) in formExt.listCity" :key="i" :value="item.id + '|' + item.rownum">
                {{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="统计数据适用年份" prop="sjnf">
            <Select prefix="ios-cube-outline" v-model="form.sjnf" @on-change="changeSjnf">
              <Option v-for="(item, i) in this.$store.state.dict.tjsjnf" :key="i" :value="item.value">
                {{ item.title }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="全国城镇居民人均可支配收入" prop="kzpsr">
            <Input v-model="form.kzpsr" :maxlength="9" prefix="logo-bitcoin" />
          </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
          <FormItem label="平均寿命" prop="pjsm">
            <Input v-model="form.pjsm" :maxlength="5" prefix="md-sad" />
          </FormItem>
          </Col>
          <Col span="12">
          <FormItem label="统筹地区在岗职工平均工资（年）" prop="ddpjgz">
            <Input v-model="form.ddpjgz" :maxlength="9" prefix="logo-bitcoin" />
          </FormItem>
          </Col>
        </Row>
        <Form-item class="br">
          <Button @click="handleCalc" :loading="submitLoading">计算</Button>
          <Button @click="handleSubmit" :loading="submitLoading">保存</Button>
          <Button @click="handleClose">关闭</Button>
        </Form-item>
        <List :header="formExt.resultHead1" border v-show="formExt.resultVisable" size="small">
          <ListItem v-for="(item, i) in formExt.resultList1" :key="i" :value="i" style="padding-left: 50px">
            {{ item.child }}</ListItem>
        </List>
        <List :header="formExt.resultHead2" border v-show="formExt.resultVisable" size="small">
          <ListItem v-for="(item, i) in formExt.resultList2" :key="i" :value="i" style="padding-left: 50px">
            {{ item.child }}</ListItem>
        </List>
      </Form>
    </Drawer>
  </div>
</template>

<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    addGspc,
    editGspc,
    calcGspc
  } from "@/api/busi/gspc-manage/gspc";
  import {
    getProvince,
    getCity,
    getTjsjArea,
    getTjsjCountry,
  } from "@/api/busi/tjsj-manage/tjsj";
  import {
    validateNumber,
    validateMoney
  } from "@/libs/validate";
  export default {
    name: "gspcAddEdit",
    components: {},
    props: {
      data: Object,
      addEditFlag: String,
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        submitLoading: false, // 表单提交状态
        title: "工伤赔偿",
        visible: this.value,
        form: {
          // 添加或编辑表单对象初始化数据
          shr: "",
          shrq: "",
          province: "",
          city: "",
          xb: "0",
          ngzbz: "0",
          shrgz: 0,
          csrq: "",
          tglxts: 0,
          hldj: "0",
          scdj: "0",
          zyb: "0",
          ylf: 0,
          hsbzf: 0,
          jtf: 0,
          ssf: 0,
          kfzlf: 0,
          hlf: 0,
          fzqjf: 0,
          jdf: 0,
          sjnf: "0",
          kzpsr: 0,
          pjsm: 0,
          ddpjgz: 0,
          ze: 0,
          provinceCode: "",
          cityCode: "",
          tmpProvince: "",
          tmpCity: "",
        },
        formExt: {
          listProvince: [],
          listCity: [],
          cityDisabled: true,
          resultHead1: "",
          resultList1: [],
          resultHead2: "",
          resultList2: [],
          resultVisable: false,
        },
        // 表单验证规则
        formValidate: {
          shr: [{
            required: true,
            message: "不能为空",
            trigger: "blur",
          }, ],
          // province: [{
          //   required: true,
          //   message: "不能为空",
          //   trigger: "change",
          // }, ],
          // sjnf: [{
          //   required: true,
          //   message: "不能为空",
          //   trigger: "change",
          // }, ],
          shrgz: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          ylf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          hsbzf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          jtf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          ssf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          kfzlf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          hlf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          fzqjf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          jdf: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          kzpsr: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          pjsm: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          ddpjgz: [{
            validator: validateMoney,
            trigger: "blur",
          }, ],
          tglxts: [{
            validator: validateNumber,
            trigger: "blur",
          }, ],
        },
      };
    },
    methods: {
      init() {
        // 计算结果不可见
        this.formExt.resultVisable = false;

        // 省份结果集获取
        if (this.formExt.listProvince.length == 0) {
          getProvince().then((res) => {
            this.submitLoading = false;
            if (res.success) {
              this.formExt.listProvince = res.result;
            }
          });
        }

        // 编辑情况下
        if (this.addEditFlag == "2") {
          this.form = this.data;
          this.form.hldj = this.form.hldj.toString();
          this.form.xb = this.form.xb.toString();
          this.form.scdj = this.form.scdj.toString();
          this.form.zyb = this.form.zyb.toString();
          this.form.sjnf = this.form.sjnf.toString();
          if (this.form.province != "") {
            this.form.provinceCode =
              this.formExt.listProvince[this.form.province - 1].id;
            this.form.tmpProvince =
              this.form.provinceCode + "|" + this.form.province;
          }
          if (this.form.city != "") {
            getCity({
              province: this.form.provinceCode,
            }).then((res) => {
              this.submitLoading = false;
              if (res.success) {
                this.formExt.listCity = res.result;
                if (this.formExt.listCity != 0) {
                  this.formExt.cityDisabled = false;
                }
                this.form.cityCode = this.formExt.listCity[this.form.city - 1].id;
                this.form.tmpCity = this.form.cityCode + "|" + this.form.city;
              }
            });
          }
        } else {
          delete this.form.id;
          this.form.city ="";
          this.form.cityCode ="";
          this.form.province = "";
          this.form.provinceCode = "";
          this.form.tmpCity = "";
          this.form.tmpProvince = "";
        }
      },
      changeProvince() {
        if (
          this.form.tmpProvince == null ||
          this.form.tmpProvince == "" ||
          this.form.tmpProvince == undefined
        ) {
          return;
        }
        this.form.provinceCode = this.form.tmpProvince.split("|")[0];
        this.form.province = this.form.tmpProvince.split("|")[1];
        getCity({
          province: this.form.provinceCode,
        }).then((res) => {
          this.submitLoading = false;
          if (res.success) {
            this.formExt.listCity = res.result;
            if (this.formExt.listCity == 0) {
              this.form.tmpCity = "";
              this.form.city = "";
              this.form.cityCode = "";
              this.formExt.cityDisabled = true;
              this.getTjsj();
            } else {
              this.formExt.cityDisabled = false;
              this.form.tmpCity = "";
              this.form.city = "";
              this.form.cityCode = "";
              this.form.kzpsr = 0;
              this.form.pjsm = 0;
              this.form.ddpjgz = 0;
            }
          }
        });
      },
      changeCity() {
        if (
          this.form.tmpCity == null ||
          this.form.tmpCity == "" ||
          this.form.tmpCity == undefined
        ) {
          return;
        }
        this.form.cityCode = this.form.tmpCity.split("|")[0];
        this.form.city = this.form.tmpCity.split("|")[1];
        this.getTjsj();
      },
      changeSjnf() {
        this.getTjsj();
      },
      getTjsj() {
        if (this.form.province == undefined || this.form.province == "") return;
        if (
          (this.form.city == undefined || this.form.city == "") &&
          this.formExt.cityDisabled == false
        )
          return;
        if (this.form.sjnf == undefined || this.form.sjnf == "") return;
        getTjsjCountry({
          sjnf: this.$store.state.dict.tjsjnf[this.form.sjnf-1].title,
        }).then((res) => {
          this.submitLoading = false;
          if (res.success) {
            this.form.kzpsr = res.result.rjsr;
            this.form.pjsm = res.result.pjsm;
          }
        });

        var param = {
          province: this.form.provinceCode,
          city: this.form.cityCode,
          sjnf: this.$store.state.dict.tjsjnf[this.form.sjnf-1].title,
        };
        getTjsjArea(param).then((res) => {
          this.submitLoading = false;
          if (res.success) {
            this.form.ddpjgz = res.result.nsr;
          }
        });
      },
      setCurrentValue(value) {
        if (value === this.visible) {
          this.$refs.form.resetFields();
          return;
        }
        this.init();
        this.visible = value;
      },
      handleReset() {
        this.$refs.form.resetFields();
      },
      dynCheck(){
          if (this.form.province==undefined || this.form.province=="") {
            this.$Message.warning("请选择省份");
            return false;
          }
          if (this.form.sjnf==undefined || this.form.sjnf==""  || this.form.sjnf=="0") {
            this.$Message.warning("请选择数据年份");
            return false;
          }
          return true;
      },
      handleSubmit() {
        if (this.dynCheck()==false) {
           return;
        }
        this.$refs.form.validate((valid) => {
          if (valid) {
            // 防止下拉框有undefined存在
            for (var k in this.form) {
              if (this.form[k] == undefined) this.form[k] = "";
            }

            if (this.addEditFlag == "1") {
              addGspc(this.form).then((res) => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.$emit("on-submit", true);
                  this.visible = false;
                }
              });
            } else {
              editGspc(this.form).then((res) => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.$emit("on-submit", true);
                  this.visible = false;
                }
              });
            }
          }else{
            this.$Message.warning("请检查界面输入信息");
          }
        });
      },
      handleCalc() {
        if (this.dynCheck()==false) {
           return;
        }

        // 防止下拉框有undefined存在
        for (var k in this.form) {
          if (this.form[k] == undefined) this.form[k] = "";
        }
        calcGspc(this.form).then((res) => {
          this.submitLoading = false;
          if (res.success) {
            this.formExt.resultHead1 = res.result[0];
            this.formExt.resultList1 = res.result[1];
            this.form.ze = res.result[2];
            this.formExt.resultHead2 = res.result[3];
            this.formExt.resultList2 = res.result[4];
            this.formExt.resultVisable = true;
          }
        });

      },
      handleClose() {
        this.visible = false;
      },
    },
    watch: {
      value(val) {
        this.setCurrentValue(val);
      },
      visible(value) {
        this.$emit("input", value);
      },
    },
    mounted() {
      this.init();
    },
  };
</script>

<style lang="less">
  // @import "../../../styles/table-common.less";
  .drawer-content {
    overflow: auto;
  }

  .drawer-content::-webkit-scrollbar {
    display: none;
  }

  .user-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .info-title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      display: block;
      margin-right: 16px;
    }
  }

  .info-header {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 16px;
  }
</style>